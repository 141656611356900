<template>
  <div class="base-machines-list">
    <b-card>
      <authorization-guard permission="base.resource.index" silent>
        <machines-list-filtration />
      </authorization-guard>

      <div class="buttons">
        <div class="group d-flex justify-content-between">
          <vs-button
            @click="openResourceCreateModal"
            vs-variant="primary"
            v-can:store="'base.resource'"
          >
            + {{ $t('base.machines.button.addMachine') }}
          </vs-button>

          <vs-button vs-variant="light" @click="handleExport">
            <feather-icon size="14" icon="DownloadIcon" />
          </vs-button>

          <a class="d-none" ref="exportAnchorRef" />
        </div>
      </div>
      <br />

      <machines-list-table />
      <base-resource-create-modal type="vehicle" @ok="fetchData" />
    </b-card>
  </div>
</template>

<script>
import { BCard } from 'bootstrap-vue';
import MachinesListFiltration from '@/components/views/base/machines/MachinesListFiltration.vue';
import MachinesListTable from '@/components/views/base/machines/MachinesListTable.vue';
import VsButton from '@/components/ui/vs-button/VsButton.vue';
import useBaseModals from '@/hooks/base/useBaseModals';
import AuthorizationGuard from '@/components/ui/AuthorizationGuard.vue';
import BaseResourceCreateModal from '@/components/views/base/resource/modals/BaseResourceCreateModal.vue';
import baseMachinesTable from '@/hooks/tables/base/machines';
import ApiClient from '@/services/api';
import showToast from '@/libs/toasts';
import {
  ExportDataSuccess,
  ExportDataError,
} from '@/libs/toasts/feedback/module/base';
import { ref } from 'vue';
import formatDate from '@/libs/date-formatter';

export default {
  components: {
    MachinesListFiltration,
    MachinesListTable,
    VsButton,

    BCard,
    AuthorizationGuard,
    BaseResourceCreateModal,
  },
  setup() {
    const { fetchData, getFetchParams } = baseMachinesTable.value;
    const { modalCreateResource } = useBaseModals();
    const openResourceCreateModal = () => {
      modalCreateResource.value.open();
    };

    const exportAnchorRef = ref();

    const handleExport = async () => {
      const anchorRef = exportAnchorRef.value;
      if (!anchorRef) return;

      const fetchParams = getFetchParams();

      ApiClient.get('api/base/resource/export', {
        ...fetchParams,
        columnFilters: {
          ...(fetchParams.columnFilters ?? {}),
          type_ids: ['vehicle'],
        },
      })
        .then(({ data }) => {
          const timestamp = formatDate.standardTimed(Date.now());
          anchorRef.href = `data:application/xlsx;base64,${data.data}`;
          anchorRef.download = `Budokop - Maszyny - ${timestamp}.xlsx`;
          anchorRef.click();
          showToast(ExportDataSuccess);
        })
        .catch((err) => {
          showToast(ExportDataError);
          console.log('error ', err);
        });
    };

    return {
      fetchData,
      openResourceCreateModal,
      handleExport,
      exportAnchorRef,
    };
  },
};
</script>
