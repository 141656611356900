var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-tabs', {
    on: {
      "activate-tab": _vm.changeTab
    }
  }, [_c('b-tab', {
    attrs: {
      "lazy": "",
      "active": _vm.isTabActive('machines')
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function () {
        return [_c('feather-icon', {
          attrs: {
            "icon": "TruckIcon"
          }
        }), _c('span', [_vm._v("Maszyny")])];
      },
      proxy: true
    }])
  }, [_c('machines-list')], 1), _c('b-tab', {
    attrs: {
      "lazy": "",
      "active": _vm.isTabActive('reports')
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function () {
        return [_c('feather-icon', {
          attrs: {
            "icon": "ActivityIcon"
          }
        }), _c('span', [_vm._v("Raporty")])];
      },
      proxy: true
    }])
  }, [_c('refueling-list', {
    attrs: {
      "type": "vehicle"
    }
  })], 1), _c('b-tab', {
    attrs: {
      "lazy": "",
      "active": _vm.isTabActive('service')
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function () {
        return [_c('feather-icon', {
          attrs: {
            "icon": "ToolIcon"
          }
        }), _c('span', [_vm._v("Serwis")])];
      },
      proxy: true
    }])
  }, [_c('base-cars-service', {
    attrs: {
      "type": "machines"
    }
  })], 1), _c('b-tab', {
    attrs: {
      "lazy": "",
      "active": _vm.isTabActive('schedule')
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function () {
        return [_c('feather-icon', {
          attrs: {
            "icon": "ClockIcon"
          }
        }), _c('span', [_vm._v("Harmonogram")])];
      },
      proxy: true
    }])
  }, [_c('base-machines-scheduler')], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }