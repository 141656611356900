var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "card shadow-none bg-transparent border-secondary"
  }, [_c('div', {
    staticClass: "card-body"
  }, [_c('h4', {
    staticClass: "card-title"
  }, [_vm._v(" " + _vm._s(_vm.$t('global.title.filtering')) + " ")]), _c('b-row', [_c('b-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": _vm.$t('base.global.field.category')
    }
  }, [_c('base-category-picker', {
    attrs: {
      "multiple": "",
      "excluded": function (option) {
        var _option$type;
        return ((_option$type = option.type) === null || _option$type === void 0 ? void 0 : _option$type.slug) !== 'vehicle';
      }
    },
    model: {
      value: _vm.filters.category_ids,
      callback: function ($$v) {
        _vm.$set(_vm.filters, "category_ids", $$v);
      },
      expression: "filters.category_ids"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": _vm.$t('base.global.field.status')
    }
  }, [_c('base-status-picker', {
    attrs: {
      "multiple": "",
      "excluded": function (_ref) {
        var types = _ref.types;
        return !types.find(function (t) {
          return t.slug === 'vehicle';
        });
      }
    },
    model: {
      value: _vm.filters.status_ids,
      callback: function ($$v) {
        _vm.$set(_vm.filters, "status_ids", $$v);
      },
      expression: "filters.status_ids"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": _vm.$t('base.global.field.group')
    }
  }, [_c('base-group-picker', {
    attrs: {
      "multiple": ""
    },
    model: {
      value: _vm.filters.group_ids,
      callback: function ($$v) {
        _vm.$set(_vm.filters, "group_ids", $$v);
      },
      expression: "filters.group_ids"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": _vm.$t('base.global.field.producer')
    }
  }, [_c('base-producer-picker', {
    attrs: {
      "multiple": ""
    },
    model: {
      value: _vm.filters.producer_ids,
      callback: function ($$v) {
        _vm.$set(_vm.filters, "producer_ids", $$v);
      },
      expression: "filters.producer_ids"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": _vm.$t('base.global.field.technology')
    }
  }, [_c('base-technology-picker', {
    attrs: {
      "multiple": ""
    },
    model: {
      value: _vm.filters.technology_ids,
      callback: function ($$v) {
        _vm.$set(_vm.filters, "technology_ids", $$v);
      },
      expression: "filters.technology_ids"
    }
  })], 1)], 1)], 1), _c('a', {
    attrs: {
      "href": "#"
    },
    on: {
      "click": _vm.clearAllFilters
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('global.table.clearAllFilters')) + " ")])], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }