<template>
  <div class="card shadow-none bg-transparent border-secondary">
    <div class="card-body">
      <h4 class="card-title">
        {{ $t('global.title.filtering') }}
      </h4>
      <b-row>

        <b-col cols="4">
          <b-form-group
            :label="$t('base.global.field.category')"
          >
            <base-category-picker
              multiple
              v-model="filters.category_ids"
              :excluded="(option) => option.type?.slug !== 'vehicle'"
            />
          </b-form-group>
        </b-col>

        <b-col cols="4">
          <b-form-group
            :label="$t('base.global.field.status')"
          >
            <base-status-picker
              multiple
              v-model="filters.status_ids"
              :excluded="({ types }) => !types.find((t) => t.slug === 'vehicle')"
            />
          </b-form-group>
        </b-col>

        <b-col cols="4">
          <b-form-group
            :label="$t('base.global.field.group')"
          >
            <base-group-picker
              multiple
              v-model="filters.group_ids"
            />
          </b-form-group>
        </b-col>

        <b-col cols="4">
          <b-form-group
            :label="$t('base.global.field.producer')"
          >
            <base-producer-picker
              multiple
              v-model="filters.producer_ids"
            />
          </b-form-group>
        </b-col>

        <b-col cols="4">
          <b-form-group
            :label="$t('base.global.field.technology')"
          >
            <base-technology-picker
              multiple
              v-model="filters.technology_ids"
            />
          </b-form-group>
        </b-col>

      </b-row>

      <a href="#" @click="clearAllFilters">
        {{ $t('global.table.clearAllFilters') }}
      </a>
    </div>
  </div>
</template>

<script>
import { BCol, BRow, BFormGroup } from 'bootstrap-vue';
import baseMachinesTable from '@/hooks/tables/base/machines';
import BaseCategoryPicker from '@/components/module/base/BaseCategoryPicker.vue';
import BaseStatusPicker from '@/components/module/base/BaseStatusPicker.vue';
import BaseGroupPicker from '@/components/module/base/BaseGroupPicker.vue';
import BaseProducerPicker from '@/components/module/base/BaseProducerPicker.vue';
import BaseTechnologyPicker from '@/components/module/base/BaseTechnologyPicker.vue';

export default {
  name: 'MachinesListFiltration',
  setup() {
    const { table, filters, clearAllFilters } = baseMachinesTable.value;

    return {
      filters,
      clearAllFilters,
      table,
    };
  },
  components: {
    BCol,
    BRow,
    BFormGroup,

    BaseStatusPicker,
    BaseGroupPicker,
    BaseProducerPicker,
    BaseTechnologyPicker,
    BaseCategoryPicker,
  },
};
</script>
