<template>
  <b-tabs
    @activate-tab="changeTab"
  >

    <b-tab
      lazy
      :active="isTabActive('machines')"
      >
      <template #title>
        <feather-icon icon="TruckIcon" />
        <span>Maszyny</span>
      </template>

      <machines-list />
    </b-tab>

    <b-tab lazy :active="isTabActive('reports')">
      <template #title>
        <feather-icon icon="ActivityIcon" />
        <span>Raporty</span>
      </template>

      <refueling-list type="vehicle" />
    </b-tab>

    <b-tab lazy :active="isTabActive('service')">
      <template #title>
        <feather-icon icon="ToolIcon" />
        <span>Serwis</span>
      </template>

      <base-cars-service
        type="machines"
      />
    </b-tab>

    <b-tab lazy :active="isTabActive('schedule')">
      <template #title>
        <feather-icon icon="ClockIcon" />
        <span>Harmonogram</span>
      </template>

      <base-machines-scheduler />
    </b-tab>

  </b-tabs>
</template>

<script>
import {
  BTabs,
  BTab,
} from 'bootstrap-vue';
import MachinesList from '@/views/base/machines/MachinesList.vue';
import RefuelingList from '@/views/base/machines/RefuelingList.vue';
import { computed } from 'vue';
import { useRouter } from '@/@core/utils/utils';
import BaseMachinesScheduler from './BaseMachinesScheduler.vue';
import BaseCarsService from '../cars/BaseCarsService.vue';

export default {
  name: 'BaseMachines',
  setup() {
    /**
     * route-based tabs should be looked into
     *
     * FIXME: refactor this and probably get rid of
     * activetab meta inside specific routes
     */
    const { route, router } = useRouter();

    const activeTab = computed(() => route.value.meta?.activeTab);
    const isTabActive = (tabName) => activeTab.value === tabName;

    const tabRoutes = {
      0: 'base.machines',
      1: 'base.machines.reports',
      2: 'base.machines.service',
      3: 'base.machines.schedule',
    };

    const changeTab = (to) => {
      const desiredRoute = tabRoutes?.[to];

      if (!desiredRoute) return;
      if (desiredRoute === route.value.name) return;

      router.push({
        name: desiredRoute,
      });
    };

    return { isTabActive, activeTab, changeTab };
  },
  components: {
    RefuelingList,
    MachinesList,
    BTabs,
    BTab,
    BaseMachinesScheduler,
    BaseCarsService,
  },
};
</script>

<style scoped>

</style>
