<template>
  <div class="base-machines-list-table">
    <authorization-guard
      permission="base.resource.index"
    >
      <vs-table showActionButton
        mode="remote"
        :columns="columns"
        :rows="$store.state.base.vehicles.vehicles"
        :totalRecords="table.totalRecords"
        :current-page="table.page"
        :checkSort="() => {}"
        :perPage="table.perPage"
        :searchTerm="searchTerm"
        :actionItems="actionItems"
        :isLoading="table.isLoading"
        @on-row-click="openDetails"
        @searchTermChange="(term) => updateSearchTerm(term)"
        @on-page-change="onPageChange"
        @on-per-page-change="onPageChange"
        >
        <template v-slot:row="{ column, row }" >
          <span v-if="column.field === 'name'">
            {{ row.name }}
          </span>

          <span v-if="column.field === 'producer'">
            {{ row.producer_model?.producer?.name }}
          </span>

          <span v-if="column.field === 'category'">
            {{ row.category?.name }}
          </span>

          <span v-if="column.field === 'serial_no'">
            {{ row.serial_number }}
          </span>

          <span v-if="column.field === 'status'">
            <status-badge :status_id="row.base_status_id" />
          </span>

          <span v-if="column.field === 'mth'">
            {{ row.meter_status?.status
              ? row.meter_status?.status
              : row.meter_status_card_index
            }}
            {{ unitStore.getShortcode(row.category.unit_id) }}
          </span>

        </template>
      </vs-table>
    </authorization-guard>
  </div>
</template>

<script>
import router from '@/router';

import StatusBadge from '@/components/ui/status-badge/Machine.vue';
import VsTable from '@/components/ui/vs-table/Table.vue';
import baseMachinesTable from '@/hooks/tables/base/machines';
import AuthorizationGuard from '@/components/ui/AuthorizationGuard.vue';
import useCoreUnitStore from '@/stores/module/core/useCoreUnitStore';

export default {
  name: 'MachinesListTable',
  components: {
    VsTable,
    StatusBadge,
    AuthorizationGuard,
  },
  setup() {
    const {
      table, updateSearchTerm, searchTerm, columns, fetchData,
      onPageChange, rows,
    } = baseMachinesTable.value;
    const unitStore = useCoreUnitStore();

    fetchData();

    const actionItems = [
      {
        title: 'Edytuj',
        icon: 'Edit2',
        callback: (obj) => {
          router.push({ name: 'base.machines.details.settings', params: { id: obj.id } });
        },
      },
    ];

    return {
      table,
      rows,
      updateSearchTerm,
      searchTerm,
      columns,
      onPageChange,
      actionItems,
      unitStore,
    };
  },
  methods: {
    openDetails(data) {
      this.$router.push({ name: 'base.machines.details', params: { id: data.row.id } });
    },
  },
};
</script>
