var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "base-machines-list-table"
  }, [_c('authorization-guard', {
    attrs: {
      "permission": "base.resource.index"
    }
  }, [_c('vs-table', {
    attrs: {
      "showActionButton": "",
      "mode": "remote",
      "columns": _vm.columns,
      "rows": _vm.$store.state.base.vehicles.vehicles,
      "totalRecords": _vm.table.totalRecords,
      "current-page": _vm.table.page,
      "checkSort": function () {},
      "perPage": _vm.table.perPage,
      "searchTerm": _vm.searchTerm,
      "actionItems": _vm.actionItems,
      "isLoading": _vm.table.isLoading
    },
    on: {
      "on-row-click": _vm.openDetails,
      "searchTermChange": function (term) {
        return _vm.updateSearchTerm(term);
      },
      "on-page-change": _vm.onPageChange,
      "on-per-page-change": _vm.onPageChange
    },
    scopedSlots: _vm._u([{
      key: "row",
      fn: function (_ref) {
        var _row$producer_model, _row$producer_model$p, _row$category, _row$meter_status, _row$meter_status2;
        var column = _ref.column,
          row = _ref.row;
        return [column.field === 'name' ? _c('span', [_vm._v(" " + _vm._s(row.name) + " ")]) : _vm._e(), column.field === 'producer' ? _c('span', [_vm._v(" " + _vm._s((_row$producer_model = row.producer_model) === null || _row$producer_model === void 0 ? void 0 : (_row$producer_model$p = _row$producer_model.producer) === null || _row$producer_model$p === void 0 ? void 0 : _row$producer_model$p.name) + " ")]) : _vm._e(), column.field === 'category' ? _c('span', [_vm._v(" " + _vm._s((_row$category = row.category) === null || _row$category === void 0 ? void 0 : _row$category.name) + " ")]) : _vm._e(), column.field === 'serial_no' ? _c('span', [_vm._v(" " + _vm._s(row.serial_number) + " ")]) : _vm._e(), column.field === 'status' ? _c('span', [_c('status-badge', {
          attrs: {
            "status_id": row.base_status_id
          }
        })], 1) : _vm._e(), column.field === 'mth' ? _c('span', [_vm._v(" " + _vm._s((_row$meter_status = row.meter_status) !== null && _row$meter_status !== void 0 && _row$meter_status.status ? (_row$meter_status2 = row.meter_status) === null || _row$meter_status2 === void 0 ? void 0 : _row$meter_status2.status : row.meter_status_card_index) + " " + _vm._s(_vm.unitStore.getShortcode(row.category.unit_id)) + " ")]) : _vm._e()];
      }
    }])
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }